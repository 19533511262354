import React, { Component } from 'react';
import { navigate } from "gatsby";
import styled, { keyframes } from "styled-components";
import { auth, firebase } from '../firebase';
import { Layout, Content } from '../Components/AppChrome';
import optimumLogo from "../images/optimum.png";
import googleSignIn from "../images/btn_google_signin_light_normal_web@2x.png";
import googleSignInHover from "../images/btn_google_signin_light_focus_web@2x.png";
import googleSignInClick from "../images/btn_google_signin_light_pressed_web@2x.png";

class IndexPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authenticating: false,
      click: false,
      hover: false
    };
  }

  onSignIn = () => {
    console.log("Signing in");
    this.setState({click: true});
    auth.doGoogleSignInWithRedirect();
  }

  onMouseOver = () => {
    this.setState({hover: true});
  }

  onMouseOut = () => {
    this.setState({hover: false});
  }

  componentDidMount() {
    this.setState({
      authenticating: true
    });

    firebase.auth.onAuthStateChanged(user => {
      if (user) {
        navigate(`/user/${user.email}`);
      } else {
        this.setState({
          authenticating: false,
          click: false,
          hover: false
        });
        console.log("Not authenticated");
      }
    });
  }

  renderLogin() {
    return (
      <Layout>
        <Content>
          <OptimumLogo src={optimumLogo} />

          { this.state.click ?
            <ButtonImage src={googleSignInClick} />
          :
            this.state.hover ?
              <ButtonImage src={googleSignInHover} onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} onClick={this.onSignIn} />
            :
              <ButtonImage src={googleSignIn} onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} onClick={this.onSignIn} />
          }
        </Content>
      </Layout>
    );
  }

  renderAuthenticating() {
    return (
      <Layout>
        <Content>
          <OptimumLogoFadeInOut src={optimumLogo} />
        </Content>
      </Layout>
    );
  }

  render() {
    if (this.state.authenticating) {
      return this.renderAuthenticating();
    }

    return this.renderLogin();
  }
}

export default IndexPage;

const OptimumLogo = styled.img`
  display: block;
  width: 100%;
  padding: 40% 0;
`;

const keyFrames = keyframes`
  0% { opacity: 0; }
  10% { opacity: 1; }
  75% { opacity: 1; }
  100% { opacity: 0; }
`

const OptimumLogoFadeInOut = styled.img`
  display: block;
  width: 100%;
  padding: 40% 0;
  opacity: 0;
  animation: ${keyFrames} 2s ease-in-out 0s 1;
`;

const ButtonImage = styled.img`
  display: block;
  width: 191px;
  margin: auto auto;
`;
